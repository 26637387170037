<app-login #name [isProviderSettingPage]="true" (signIn)="SignIn($event)" *ngIf="viewLoginPage"></app-login>

<div class="kt-portlet--no_navigation m-0" *ngIf="providerSettingPage">
  <section class="features-icons bg-light text-center">
    <div class="kt-portlet profile-area p-4">
      <div class="row">
        <div class="col-md-5 mt-5">
          <h1> Medlabs 30 Years Health Campaign</h1>
        </div>
        <div class="col-2">
          <a>
            <img alt="Logo"
                 src="./assets/media/logos/rght-side-menu.png">
          </a>
        </div>
        <div class="col-md-5 mt-5">
          <h1>حملة مدلاب 30 عاما لصحة الأردن</h1>
        </div>

      </div>
    </div>
  </section>
  <div class="col-md-12 mt-3">
    <div class="kt-portlet kt-portlet--head-sm">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            Provider Settings and status
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <div class="row">
          <div class="col-md-12 mb-3">
            <label></label>
            <div class="kt-form__actions kt-form__actions--right" style="float: right !important">
              <button class="btn btn-primary" type="button" (click)="logOut()">Log Out</button>
            </div>
          </div>
          <p-table #dt [value]="providerSettings" dataKey="id" [totalRecords]="providerSettings?.length" styleClass="p-datatable-customers" [rowHover]="true"
                   [paginator]="false" [rows]="providerSettings?.length" pageLinks="3" [rowsPerPageOptions]="[10,20,40,80]">
            <ng-template pTemplate="header">
              <tr>
                <th style="width:5%;">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Code
                  </div>
                </th>
                <th style="width:38%;">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Name
                  </div>
                </th>
                <th style="width:10%;">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    City
                  </div>
                </th>
                <th style="width:11%;">
                  <div class="p-d-flex p-jc-between p-ai-center"> 
                    Registration Limit
                  </div>
                </th>
                <th style="width:11%;">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Campaign Active ?
                  </div>
                </th>
                <th style="width:10%;" pSortableColumn="currentQueue">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Current Queue <p-sortIcon field="currentQueue"></p-sortIcon>
                  </div>
                </th>
                <th style="width:10%;" pSortableColumn="actualVisit">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Actual Visits <p-sortIcon field="actualVisit"></p-sortIcon>
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-providerSetting>
              <tr class="p-selectable-row">
                <td>
                  {{providerSetting.code}}
                </td>
                <td>
                  <input type="text" class="form-control" [(ngModel)]="providerSetting.providerName" (blur)="UpdateProviderSettings(providerSetting)">
                </td>
                <td>
                  <div class="input-group">
                    <div class="col-md-12">
                      <label></label>
                      <p-dropdown [options]="governorates" optionLabel="name" [showClear]="false" inputId="governorate" styleClass='form-control'
                                  placeholder="Please Select" [(ngModel)]="providerSetting.cityId" (onChange)="UpdateProviderSettings(providerSetting)"></p-dropdown>
                    </div>
                  </div>

                </td>
                <td>
                  <input type="number" class="form-control" [(ngModel)]="providerSetting.visitsMaxLimit" (blur)="UpdateProviderSettings(providerSetting)">
                </td>
                <td>
                  <p-inputSwitch [(ngModel)]="providerSetting.isCampaignActive" (onChange)="UpdateProviderSettings(providerSetting)"></p-inputSwitch>
                </td>
                <td>
                  {{providerSetting.currentQueue}}
                </td>
                <td>
                  {{providerSetting.actualVisit}}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="12">No Data Found</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
