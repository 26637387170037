<app-login #name (GetProviderSettingId)="GetProviderSettingId($event)" *ngIf="viewLoginPage"></app-login>


<div class="kt-portlet--no_navigation m-0" *ngIf="viewDetails">
  <section class="features-icons bg-light text-center">
    <div class="kt-portlet profile-area p-4">
      <div class="row">
        <div class="col-md-5 mt-5">
          <h1> Medlabs 30 Years Health Campaign</h1>
        </div>
        <div class="col-2">
          <a>
            <img alt="Logo"
                 src="./assets/media/logos/rght-side-menu.png">
          </a>
        </div>
        <div class="col-md-5 mt-5">
          <h1>حملة مدلاب 30 عاما لصحة الأردن</h1>
        </div>

      </div>
    </div>
  </section>
  <div class="col-md-12 mt-3">
    <div class="kt-portlet kt-portlet--head-sm">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            Code
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
         
          <div class="dropdown dropdown-inline">
            <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-lg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="flaticon-more-1 text-danger"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <ul class="kt-nav">
                <li class="kt-nav__item">
                  <a class="kt-nav__link" (click)="Logout()">
                    <i class="log-out-color kt-nav__link-icon fa fa-sign-out-alt"></i>
                    <span class="kt-nav__link-text log-out-color">Log Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="kt-portlet__body">
        <div class="row">
          <div class="form-group form-group-sm col-md-5">
            <label>Code</label>
            <input type="number" [(ngModel)]="code" class="form-control required" (blur)="CheckQrCode()" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 mt-3" *ngIf="visitRegistration">
    <div class="kt-portlet kt-portlet--head-sm">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            Visit Registration
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <div class="row">
          <div class="form-group form-group-sm col-md-6">
            <label>Name</label>
            <input type="text" [(ngModel)]="visitRegistration.patientFullName" class="form-control" [disabled]="true" />
          </div>

          <div class="form-group form-group-sm col-md-6">
            <label>Identity No.</label>
            <input type="text" [(ngModel)]="visitRegistration.identityNo" class="form-control" [disabled]="true" />
          </div>
          <div class="form-group form-group-sm col-md-6">
            <label>Mobile No.</label>
            <input type="text" [(ngModel)]="visitRegistration.mobileNo" class="form-control" [disabled]="true" />
          </div>
          <div class="form-group form-group-sm col-md-6">
            <label>Visit No.</label>
            <input type="text" [(ngModel)]="visitRegistration.carenetVisitNo" class="form-control" [disabled]="true" />
          </div>
          <div class="form-group form-group-sm col-md-12 d-queue-code">
            <span>Queue No. : </span>
            <span class="text-queue-code">
              {{visitRegistration.queueCode}}{{visitRegistration.queueSequence}}
            </span>

          </div>
          <div class="form-group form-group-sm col-md-12">
            <div class="kt-form__actions kt-form__actions--right" style="float: right !important; margin-top: 6px;">
              <button class="btn btn-primary" type="button" (click)="Confirm(visitRegistration.refProviderSettingsId)">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="remove-space text-center" *ngIf="errorMessage">
    <h3 class="no-provider-title">{{errorMessage}}</h3>
  </div>

</div>



<p-dialog class="prime-form-control" [appendTo]="'body'" header="" [style]="{width: '60%',left:'15px'}"
          [responsive]="true" [(visible)]="showWarningPopub" [modal]="true" [draggable]="false">
  <div class=" kt-portlet--head-sm">
    <div class="row">
      <div class="col-12">
        <div class="content_Class">
          <div class="text-center">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <h3 class="no-provider-title">
              لا يمكن اٍتمام الطلب ,الطلب مسجل بمختبر اخر
            </h3> 
                  <h5 class="no-provider-title-2" > The application can't be completed , the application is registered with another laboratory </h5> 
          </div>
        </div>
      </div>
    </div>
  </div>

</p-dialog>
