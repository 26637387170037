export const environment = {
  storageToken: 'MedlUToken',
  storageUserName: 'UserName',
  storagePassword: 'Password',
  showSignature: true,
  isArabicName: false,
  branchId: 1,
  companyId: 1,
  production: false,
  url: 'https://camp1api.medlabsresults.com',
  attributesOneId: 22706,
  attributesOneYesId: 20836,
  attributesOneNoId: 20835,
  attributesTwoId: 22707,
  attributesTwoYesId: 20838,
  attributesTwoNoId: 20837,
  attributesThreeId: 22708,
  attributesThreeYesId: 20840,
  attributesThreeNoId: 20839,
  attributesFourId: 22709,
  attributesFourYesId: 20842,
  attributesFourNoId: 20841,
  attributesFiveId: 22710,
  attributesFiveYesId: 20844,
  attributesFiveNoId: 20843,
  qrUrl: "https://camp1api.medlabsresults.com/QR/",
  username: "Admin",
  password: "Medlabs@Admin$"
};
