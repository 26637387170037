import { Component, OnInit } from '@angular/core';
import { CommonService, MessageType } from '../services/shared/common.service';
import { ProviderSettingService } from '../services/provider-setting.service';
import { ProviderSettings } from '../models/providerSettings';

@Component({
  selector: 'app-provider-settings',
  templateUrl: './provider-settings.component.html',
  styleUrls: ['./provider-settings.component.scss']
})

export class ProviderSettingsComponent implements OnInit {

  providerSettings: any[] = [];
  governorates: any[];
  viewLoginPage: boolean = true;
  providerSettingPage: boolean = false;

  constructor(private cs: CommonService, private providerSettingService: ProviderSettingService) {
 
  }

  ngOnInit(): void {
    let verifiedProvider=  localStorage.getItem("verifiedProvider");
    if (verifiedProvider) {
      this.viewLoginPage = false;
      this.providerSettingPage = true;
      this.fillGovernorate();
      this.GetAllProviderSettings();
    }
    //this.fillGovernorate();
    //this.GetAllProviderSettings();
  }

  async GetAllProviderSettings() {
    this.providerSettings = [];
    await this.providerSettingService.GetAllProviderSettings().subscribe(data => {
      if (data.data) {
        this.providerSettings = data.data;
        for (var i = 0; i < this.providerSettings.length; i++) {
          this.providerSettings[i].cityId = this.governorates.find(a => a.value == this.providerSettings[i].cityId);
        }
      }
    });
  }

  fillGovernorate() {
    this.governorates = [
      { name: 'Amman', value: 12 },
      { name: 'Rusifa', value: 2 },
      { name: 'Zarqa', value: 4 },
      { name: 'Irbid', value: 6 },
      { name: 'Aqaba', value: 5 },
      { name: 'Sahab', value: 457 },
      { name: 'Karak', value: 8 },
      { name: 'Maan', value: 9 },
      { name: 'Tafila', value: 10 },
      { name: 'Al-Balqa', value: 11 },
      { name: 'Mafraq', value: 26 },
      { name: 'Alfuheis', value: 27 },
      { name: 'Marj Al Hamam', value: 28 },
      { name: 'Mutah', value: 29 },
      { name: 'Ghor', value: 31 },
      { name: 'Jerash', value: 13 },
      { name: 'Petra', value: 33 },
      { name: 'Madaba', value: 15 },
      { name: 'Ajlun', value: 3 },
      { name: 'Azraq', value: 336 },
      { name: 'Ramtha', value: 338 },
      { name: 'Tafilah', value: 10 },

    ];
  }

  async UpdateProviderSettings(providerSetting) {
    
    providerSetting.cityId = providerSetting.cityId ? providerSetting.cityId.value : '';

    if (!providerSetting.providerName) {
      this.cs.pushMessage(MessageType.Error, null, 'Please fill Provider Name');
      return;
    }
      

    if (!providerSetting.visitsMaxLimit) {
      this.cs.pushMessage(MessageType.Error, null, 'Please fill visits Limit');
      return;
    }
    
    if (!providerSetting.cityId) {
      this.cs.pushMessage(MessageType.Error, null, 'Please select city');
      return;
    }

    await this.providerSettingService.UpdateProviderSettings(providerSetting).subscribe(data => {
      if (!data.apiMessage) {
        this.GetAllProviderSettings();
        this.cs.pushMessage(MessageType.Success, null, 'Opertaion Done Successfully');
        //this.GetAllProviderSettings();
      }
      else {
        this.cs.pushMessage(MessageType.Warn, null, data.apiMessage);
      }
    });
  }

  SignIn(data) {
    if (data) {
      this.viewLoginPage = false;
      this.providerSettingPage = true;
      this.fillGovernorate();
      this.GetAllProviderSettings();
      localStorage.setItem("verifiedProvider", "verifiedProvider");
    }
  }

  logOut() {
    this.viewLoginPage = true;
    this.providerSettingPage = false;
    localStorage.removeItem("verifiedProvider");
  }
}
