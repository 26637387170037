import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EcryptDecryptService {
  key: string = "Prov!der@E$k@";
  private _key = CryptoJS.enc.Utf8.parse('1203199320052021');
  private _iv = CryptoJS.enc.Utf8.parse('1203199320052021');

  constructor() {
  }

  /** Method to Ecrypt / Decrypt */
  public Ecrypt(text: string): string {
    if (text)
      return CryptoJS.AES.encrypt(text, this.key).toString();
  }

  public Decrypt(text: string): string {
    if (text)
      return CryptoJS.AES.decrypt(text, this.key).toString(CryptoJS.enc.Utf8);
  }

  encryptUsingAES256(text): any {
    if (text) {
      var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this._key, {
        keySize: 128 / 8,
        iv: this._iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.toString();
    }

  }

//  Ecrypt(word: string): string {
//      let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), this.key).toString()
//  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
//  return encData
//}

 
//  Decrypt(word: string): string {
//    let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8)
//    let bytes = CryptoJS.AES.decrypt(decData, this.key).toString(CryptoJS.enc.Utf8)
//  return JSON.parse(bytes)
//}


}
