import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { environment } from '../../environments/environment';
import { TokenHandlerService } from './shared/token-handler.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private tokenhandler: TokenHandlerService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
    let token = localStorage.getItem(environment.storageToken);    
    if (token && !this.tokenhandler.isTokenExpired()) {
      //debugger;
      return true;
    }
    else {

      localStorage.clear();
      this.router.navigateByUrl('');
      return false
    }

    //  this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } }); 
    //this.router.navigate(['/login']);
    //this.router.navigateByUrl('');
    //return false

  }


}
