<div class="kt-portlet--no_navigation m-0">
  <section class="features-icons bg-light text-center">
    <div class="kt-portlet profile-area p-4">
      <div class="row">
        <div class="col-md-5 mt-5">
          <h1> Medlabs 30 Years Health Campaign</h1>
        </div>
        <div class="col-2">
          <a>
            <img alt="Logo"
                 src="./assets/media/logos/rght-side-menu.png">
          </a>
        </div>
        <div class="col-md-5 mt-5">
          <h1>حملة مدلاب 30 عاما لصحة الأردن</h1>
        </div>

      </div>
    </div>
  </section>
  <div class="col-md-12 mt-3">
    <div class="kt-portlet kt-portlet--head-sm">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            Sign in
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <div class="row">
          <div class="form-group form-group-sm col-md-5">
            <label>Username</label>
            <input type="text" [(ngModel)]="username" class="form-control required" />
          </div>
          <div class="form-group form-group-sm col-md-5">
            <label>Password</label>
            <input type="password" [(ngModel)]="password" class="form-control required" />
          </div>
          <div class="col-md-2 mt-1" *ngIf="!isProviderSettingPage">
            <label></label>
            <div class="kt-form__actions kt-form__actions--right" style="float: right !important; margin-top: 20px;">
              <button class="btn btn-primary" type="button" [disabled]="username == null || username == '' || password == null || password == ''" (click)="SignIn()">Sign in</button>
            </div>
          </div>
          <div class="col-md-2 mt-1" *ngIf="isProviderSettingPage">
            <label></label>
            <div class="kt-form__actions kt-form__actions--right" style="float: right !important; margin-top: 20px;">
              <button class="btn btn-primary" type="button" [disabled]="username == null || username == '' || password == null || password == ''" (click)="SignInProviderSetting()">Sign in</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
