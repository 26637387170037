import { Component, OnInit } from '@angular/core';
import { CommonService, MessageType } from '../services/shared/common.service';
import { ProviderSettingService } from '../services/provider-setting.service';
import { ProviderSettings } from '../models/providerSettings';

@Component({
  selector: 'app-lab-visit-regestration',
  templateUrl: './lab-visit-regestration.component.html',
  styleUrls: ['./lab-visit-regestration.component.scss']
})

export class LabVisitRegestrationComponent implements OnInit {

  
  code: any;
  visitRegistration: any;
  providerSettingId: any;
  viewDetails: boolean = false;
  showWarningPopub: boolean = false;
  viewLoginPage: boolean = true;
  errorMessage: any = '';
  constructor(private cs: CommonService, private providerSettingService: ProviderSettingService) {
 
  }

  ngOnInit(): void {

  }

  GetProviderSettingId(data) {
    this.providerSettingId = data
    if (data > 0) {
      this.viewDetails = true;
      this.viewLoginPage = false;
      this.errorMessage = '';
    }
  }

  Logout() {
    this.visitRegistration = null;
    this.providerSettingId = 0;
    this.viewDetails = false;
    this.viewLoginPage = true;
    this.code = null;
    this.errorMessage = '';
  }

  async CheckQrCode() {
    this.visitRegistration = null;
    this.errorMessage = '';
    await this.providerSettingService.CheckQrCode(this.code).toPromise().then(data => {
      if (data.data && !data.apiMessage) {
        this.visitRegistration = data.data;
      }
      else if (data.apiMessage) {
        this.errorMessage = data.apiMessage;
      }
    });
  }

  async Confirm(refProviderSettingsId) {
    if (refProviderSettingsId != this.providerSettingId) {
      this.showWarningPopub = true;
      return;
    }

    let id = this.visitRegistration.id
    
    await this.providerSettingService.UpdateVisitRegistration(id).toPromise().then(data => {
      if (!data.apiMessage) {
        this.cs.pushMessage(MessageType.Success, null, 'Opertaion Done Successfully');
        this.code = null;
        this.visitRegistration = null;
      }
      else {
        this.cs.pushMessage(MessageType.Warn, null, data.apiMessage);
      }
    });
  }
}
