import { CommonService, MessageType } from '../services/shared/common.service';
import { ProviderSettingService } from '../services/provider-setting.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})




export class LoginComponent implements OnInit {

  @Output('GetProviderSettingId') GetProviderSettingId = new EventEmitter<null>();
  @Output('signIn') signIn = new EventEmitter<any>();
  @Input('isProviderSettingPage') isProviderSettingPage = false;


  username: any;
  password: any;
  private environment;


  constructor( private cs: CommonService, private providerSettingService: ProviderSettingService) {
    this.environment = environment;

  }

  ngOnInit(): void {

  }

  SignIn() {
    this.providerSettingService.CheckProviderIsExist(this.username, this.password).toPromise().then(data => {
      if (data.data) {
        this.GetProviderSettingId.emit(data.data);
      } else {
        this.cs.pushMessage(MessageType.Warn, null, "Invalid Username Or Password");
      }
    })
  }
  
  SignInProviderSetting() {
    let isValid = false;
    let _username = this.environment.username;
    let _password = this.environment.password;

    if (_username.toUpperCase() == this.username.toUpperCase() && _password == this.password) {
      isValid = true;
      this.signIn.emit(isValid);
    }
    else
      this.cs.pushMessage(MessageType.Warn, null, "Invalid Username Or Password");

  }

}
