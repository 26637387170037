<div class="kt-portlet--no_navigation m-0">
  <section class="features-icons bg-light text-center">
    <div class="kt-portlet profile-area p-4">
      <div class="row">
        <div class="col-3">
          <a>
            <img alt="Logo"
                 src="./assets/media/logos/Medlabs-Logo-png.png">
          </a>
        </div>
        <div class="col-md-6 mt-5">
          <h1 *ngIf="!visitRegistration?.patientPreferredLanguage || visitRegistration?.patientPreferredLanguage == 'AR'">حملة مدلاب 30 عاما لصحة الأردن</h1>
          <h1 *ngIf="visitRegistration?.patientPreferredLanguage == 'EN'"> Medlabs 30 Years Health Campaign</h1>
        </div>
        <div class="col-3">
          <a>
            <img alt="Logo"
                 src="./assets/media/logos/Medlabs-30-Logo-png.png">
          </a>
        </div>
      </div>
    </div>
  </section>
  <div class="col-12">
    <div class="row">
      <div class="kt-portlet kt-portlet--head-sm search-area" data-ktportlet="true" id="kt_portlet_tools_2">
        <div class="kt-portlet__body">
          <div class="kt-portlet__content">
            <div class="row" *ngIf="visitRegistration != null">
              <div class="input-group">
                <div class="col-md-12">
                  <label for="range"><span>Name / </span> <span>الاسم</span></label>
                  <input type="text" class="form-control" [(ngModel)]="fullName" disabled>
                </div>
                <div class="col-md-12">
                  <label for="range"><span>Identity No. / </span> <span>رقم المعرف</span></label>
                  <input type="text" class="form-control" [(ngModel)]="identityNo" disabled>
                </div>
                <div class="col-md-12">
                  <label for="range"><span>Phone No. / </span> <span>رقم الهاتف</span></label>
                  <input type="text" class="form-control" [(ngModel)]="phoneNo" disabled>
                </div>
                <div class="col-md-12">
                  <label for="range"><span>Visit No. / </span> <span>رقم الزيارة</span></label>
                  <input type="text" class="form-control" [(ngModel)]="visitNo" disabled>
                </div>
              </div>

              <!--Show When Status New-->
              <div class="msg-new" *ngIf="isNew">
                <div class="msg-en" *ngIf="isEnglishLang">
                  <h1>Welcome to MedLabs</h1>
                  <p>Please show the below QR code at the lab reception</p>
                  <h1>Queue No. : <span style="color: #e51d14;">{{queueNo}}</span></h1>

                  <p>
                    Kindly note the due to the heavy load,
                    If you miss your turn, you will have to-register
                  </p>

                  <!--QR Image-->
                  <img [src]="QRUrl" style="width: 15%;" />
                  <h1>{{visitRegistrationId}}</h1>
                </div>
                <div class="msg-ar" *ngIf="!isEnglishLang">
                  <h1 class="ar">نرحب بك في مختبرات MedLabs</h1>
                  <p class="ar">يرجى ابراز ال QR ادناه لموظف الإستقبال في المختبر</p>
                  <h1 class="ar">رقم الدور : <span style="color: #e51d14;">{{queueNo}}</span></h1>

                  <p>
                    يرجى العلم أنه بسبب ضغط الزائرين
                    إذا جاء موعد رقم الدور الخاص بك ولم تكن متواجد, عليك تعبئة الطلب مرة أخرى
                  </p>

                  <!--QR Image-->
                  <img [src]="QRUrl" style="width: 15%;" />
                  <h1>{{visitRegistrationId}}</h1>
                </div>
              </div>

              <!--Show When Status Pending/Completed-->
              <div class="msg-pending-completed" *ngIf="!isNew">
                <div class="msg-en" *ngIf="isEnglishLang">
                  <h1>Welcome to MedLabs</h1>
                  <h1 style="color: #e51d14;">Result Status : {{resultStatus}}</h1>

                  <p>
                    * Refresh the link sent via SMS to follow you result status
                  </p>
                  <p>
                    * Refresh will be finalized within 6 days
                  </p>
                </div>
                <div class="msg-ar" *ngIf="!isEnglishLang">
                  <h1 class="ar">نرحب بك في مختبرات MedLabs</h1>
                  <h1 style="color: #e51d14;" class="ar">حالة النتيجة : {{resultStatus}}</h1>

                  <p class="ar">
                    * استمر بتحديث الرابط المرسل في SMS لمتابعة حالة النتيجة
                  </p>
                  <p class="ar">
                    * سيتم الانتهاء من النتائج خلال 6 أيام
                  </p>
                </div>
              </div>
            </div>
            <div class="row no-data-found-msg" *ngIf="visitRegistration == null">
              <h1>No Data Found, لا يوجد معلومات</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- begin:: Footer -->

<div class="kt-footer  kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
  <div class="kt-container  kt-container--fluid ">
    <div _ngcontent-gul-c63="" class="kt-footer__copyright">
      2023 © <a _ngcontent-gul-c63="" href="https://www.eskadenia.com/" target="_blank" title="ESKADENIA Software" class="kt-link kt-font-brand">
        ESKADENIA Software
      </a>.&nbsp;All rights reserved.
    </div>
    <div class="kt-footer__menu">
      <a href="https://eskadenia.com" target="_blank" class="img" title="ESKADENIA Software"><img src="assets/media/logos/logo-dark.png" alt="ESKADENIA Software"></a>
    </div>
  </div>
</div>


