import { Component, OnInit } from '@angular/core';
import { EcryptDecryptService } from '../services/shared/ecrypt-decrypt.service';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '../services/patient.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-registration-tracking',
  templateUrl: './registration-tracking.component.html',
  styleUrls: ['./registration-tracking.component.scss']
})
export class RegistrationTrackingComponent implements OnInit {

  //#region Properties
  private environment;

  visitRegistration: any = null;
  visitRegistrationId: number = -1;

  fullName: string = "";
  identityNo: string = "";
  phoneNo: string = "";
  visitNo: string = "";
  queueNo: string = "";
  resultStatus: string = "";
  isEnglishLang: boolean = true;
  isNew: boolean = true;
  QRUrl: string = "";

  //#endregion

  constructor(private ecryptDecryptService: EcryptDecryptService, private activatedRoute: ActivatedRoute, private patientService: PatientService) {
    this.environment = environment;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['id'] != null)
        this.visitRegistrationId = params['id'].slice(0, -5);
 
    });
  }

  ngOnInit(): void {
    this.FillVisitRegistrationById(this.visitRegistrationId);
  }

  //#region Events

  //#endregion

  //#region Methods

  async FillVisitRegistrationById(visitRegistrationId) {
    this.visitRegistration = null;
    await this.patientService.GetVisitRegistrationById(visitRegistrationId).subscribe(data => {
      if (data.data) {
        this.visitRegistration = data.data;
        this.SetData();
      }
    });
  }

  SetData() {
    this.fullName = this.visitRegistration.patientFullName;
    this.identityNo = this.visitRegistration.identityNo;
    this.phoneNo = this.visitRegistration.mobileNo;
    this.visitNo = this.visitRegistration.carenetVisitNo;
    this.queueNo = this.visitRegistration.queueCode + this.visitRegistration.queueSequence;
    this.isEnglishLang = this.visitRegistration.patientPreferredLanguage == "EN" ? true : false;

    if (this.isEnglishLang) {
      this.resultStatus = this.visitRegistration.labOrderStatus;
    } else {
      if (this.visitRegistration.labOrderStatus.toLowerCase() == "in progress")
        this.resultStatus = "قيد العمل"
      if (this.visitRegistration.labOrderStatus.toLowerCase() == "completed")
        this.resultStatus = "مكتمل"
    }

    this.isNew = this.visitRegistration.labOrderStatus == "New";
    this.QRUrl = environment.qrUrl + this.visitRegistration.identityNo + this.visitRegistration.id + ".png"; 
  }

  //#endregion

}
