import { Component, OnInit } from '@angular/core';
import { PatientService } from 'src/app/services/patient.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { CommonService, MessageType } from '../services/shared/common.service';
import { OrderByPipe } from '../shared/order-by.pipe';
import { first } from 'rxjs';
import { EcryptDecryptService } from '../services/shared/ecrypt-decrypt.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [OrderByPipe],
})


 

export class HomeComponent implements OnInit {
  date: Date = new Date();
  SubmitForm!: FormGroup;
  OTPForm!: FormGroup;
  selectedNationality: any;
  selectedIdentityType: any;
  nationalities: any = [];
  identityTypes: any = [];
  genders: any = [];
  months: any = [];
  days: any = [];
  selectedMonth: number = 1;
  selectedday: number;
  years: any = [];
  governorates: any = [];
  labs: any = [];
  attributesOne: any = [];
  attributesTwo: any = [];
  attributesThree: any = [];
  attributesFour: any = [];
  attributesFive: any = [];
  showAgreeCondition: boolean = false;
  showOTP: boolean = false;
  showErrorMsg: boolean = false;
  errorMsg: string = '';
  verified: string = '';
  otpId: number = 0;
  selectedMemberId: number = 0;
  selectedEncryptVisitRegistrationId: string = '';
  showInvalid: boolean = false;
  AttributesOnelabel1: string = '';
  AttributesOnelabel2: string = '';
  AttributesTwolabel1: string = '';
  AttributesTwolabel2: string = '';
  AttributesThreelabel1: string = '';
  AttributesThreelabel2: string = '';
  AttributesFourlabel1: string = '';
  AttributesFourlabel2: string = '';
  AttributesFivelabel1: string = '';
  AttributesFivelabel2: string = '';

  lblMsgOne: string = '';
  lblMsgTwo: string = '';
  showNationalLable: boolean = true;
  environment: any;



  constructor(private patientService: PatientService, private router: Router, private cs: CommonService, private orderByPipe: OrderByPipe, private ecryptDecryptService: EcryptDecryptService) {
    this.environment = environment;
  }

  ngOnInit(): void {
    this.buildSubmitForm();
    this.buildOTPForm();
    this.fillNationality();
    this.fillIdentityType();
    this.fillGender();
    this.FillMonths();
    this.Filldays(1,1992);
    this.FillYears();
    this.fillGovernorate();
    this.FillAttributesOne();
    this.FillAttributesTwo();
    this.FillAttributesThree();
    this.FillAttributesFour();
    this.FillAttributesFive();
  }

  async SubmitRegistration() {



    this.showInvalid = false;
    let verified = this.verified;    //localStorage.getItem("verified");

    let isValid = this.checkValidatorForm();
    if (isValid) {
      let identityNo = '';
      let nationalityId = this.SubmitForm.controls.nationality.value.value;
      let identityTypesId = this.SubmitForm.controls.identityTypes.value.value;
      let nationalNo = this.SubmitForm.controls.nationalNo.value;
      // let passportNo = this.SubmitForm.controls.passportNo.value;
      let firstName = this.SubmitForm.controls.firstName.value;
      let secondName = this.SubmitForm.controls.secondName.value;
      let thirdName = this.SubmitForm.controls.thirdName.value;
      let familyName = this.SubmitForm.controls.familyName.value;
      let gender = this.SubmitForm.controls.gender.value.value;
      let day = this.SubmitForm.controls.day.value;
      let month = this.SubmitForm.controls.month.value.value;
      let year = this.SubmitForm.controls.year.value;
      let mobileNo = this.SubmitForm.controls.mobileNo.value;
      let cityId = this.SubmitForm.controls.governorate.value.value;
      let providerId = this.SubmitForm.controls.lab.value.id;
      let providerCode = this.SubmitForm.controls.lab.value.code
      let email = this.SubmitForm.controls.email.value;
      let attributeOneAnswer = this.SubmitForm.controls.attributeOne.value.value;
      let attributeTwoAnswer = this.SubmitForm.controls.attributeTwo.value.value;
      let attributeThreeAnswer = this.SubmitForm.controls.attributeThree.value.value;
      let attributeFourAnswer = this.SubmitForm.controls.attributeFour.value.value;
      let attributeFiveAnswer = this.SubmitForm.controls.attributeFive.value.value;
      let labOrderStatus = 'New';

      identityNo = nationalNo;



      if (verified == 'verified') {

        await this.patientService.SaveVisitRegistration(nationalityId, identityTypesId, identityNo, firstName, secondName, thirdName, familyName, gender, day, month, year, mobileNo, cityId, providerId, providerCode,
          email, attributeOneAnswer, attributeTwoAnswer, attributeThreeAnswer, attributeFourAnswer, attributeFiveAnswer, labOrderStatus, this.selectedMemberId).subscribe(res => {
            if (res && res.data && res.apiMessage == null) {
              this.SendTrackingPageUrl(res.data);
            } else if (res && res.apiMessage == "inValidAge") {
              this.lblMsgOne = 'We apologies, You must be over 18 years to qualify for this campaign';
              this.lblMsgTwo = 'هذه الحملة تنطبق لمن هم فوق 18 سنة. نعتذر عن استقبال طلبكم';
              this.showInvalid = true;
            } else if (res && res.apiMessage == "isValid") {
              this.lblMsgOne = 'Sorry, we can’t register your request for this lab, the max limit has been reached. Please select another lab';
              this.lblMsgTwo = 'نعتذر، لا يمكن متابعة تسجيل طلبك في هذا المختبر، لقد وصل التسجيل للحد الأقصى ، يرجى اختيار مختبر آخر';
              this.showInvalid = true;
            }
          });

      } else {
        this.SendOTP();
      }
    }

 }

  async SendTrackingPageUrl(visitRegistrationId: number) {
    let encryptVisitRegistrationId = this.ecryptDecryptService.Ecrypt(visitRegistrationId.toString());
    await this.patientService.SendSMS(visitRegistrationId, encryptVisitRegistrationId).subscribe(res => {
      if (res && res.apiMessage == null) {
        this.lblMsgOne = 'Your visit has been successfully registered';
        this.lblMsgTwo = 'لقد تم تسجيل الزيارة بنجاح';
        this.showInvalid = true;
        this.SubmitForm.reset();
        this.selectedEncryptVisitRegistrationId = res.data;
      }
    });

    this.selectedMemberId = 0;
    this.selectedEncryptVisitRegistrationId = '';
    this.verified = '';
    this.otpId = 0;
    localStorage.clear();
  }


 async OTPVerification() {
    this.showErrorMsg = false;
    let OTPCode = this.OTPForm.controls.OTPCode.value;

    // let otpId = localStorage.getItem("otpId");
    if (this.otpId > 0) {
   await   this.patientService.CheckOTP(this.otpId, OTPCode).subscribe(res => {
        if (res && res.data == "") {
          this.otpId = 0;
         
          this.OTPForm.controls.OTPCode.setValue(null);
          this.showOTP = false;
          this.verified = 'verified';
          this.SubmitRegistration();
        } else {
          this.selectedEncryptVisitRegistrationId = '';
          this.verified = '';
          this.showErrorMsg = true;
          this.errorMsg = res.data;
        }
    });
    }
  }
  SendOTP() {

    

    var english = /^[a-zA-Z0-9. -_?]*$/;
 
    let iSEngSecondName = true;
    let iSEngThirdName= true;
    


    let firstName = this.SubmitForm.controls.firstName.value;
    let secondName = this.SubmitForm.controls.secondName.value;
    let thirdName = this.SubmitForm.controls.thirdName.value;
    let familyName = this.SubmitForm.controls.familyName.value;

    let iSEngfirstName = english.test(firstName);
    let iSEngFamilyName = english.test(familyName);

    if (secondName)
      iSEngSecondName = english.test(secondName);
    if (thirdName)
      iSEngThirdName = english.test(thirdName);


    let msgLangauge = (iSEngfirstName && iSEngSecondName && iSEngThirdName && iSEngFamilyName) ? "EN" : "AR";


    let mobileNo = this.SubmitForm.controls.mobileNo.value;
   
    this.buildOTPForm();
    this.patientService.SendOTP(mobileNo, msgLangauge).subscribe(res => {
      if (res && res.data && res.data > 0) {
        this.showOTP = true;
        // localStorage.removeItem("verified");
        this.verified = ''
        this.selectedEncryptVisitRegistrationId = '';
        this.otpId = res.data;
      //  localStorage.setItem("otpId", res.data);
      }
    });
  
  }
  onChangeGovernorate(event) {
    this.fillLab(event.value.value);
  }

  async onChangelabs() {
    let providerCode = this.SubmitForm.controls.lab.value.code;

   await this.patientService.IsValidRegistarion(providerCode).subscribe(res => {
     if (res && !res.data) {
       this.SubmitForm.controls.lab.setValue(null);
       this.lblMsgOne = 'Sorry, we can’t register your request for this lab, the max limit has been reached. Please select another lab';
       this.lblMsgTwo = 'نعتذر، لا يمكن متابعة تسجيل طلبك في هذا المختبر، لقد وصل التسجيل للحد الأقصى ، يرجى اختيار مختبر آخر';
       this.showInvalid=true;

      }
    });
  }


  onNationalChange(nationalNo: string) {
    this.SubmitForm.controls.firstName.enable();
    this.SubmitForm.controls.secondName.enable();
    this.SubmitForm.controls.thirdName.enable();
    this.SubmitForm.controls.familyName.enable();
    this.SubmitForm.controls.gender.enable();
    this.SubmitForm.controls.day.enable();
    this.SubmitForm.controls.month.enable();
    this.SubmitForm.controls.year.enable();
    let identityType = this.SubmitForm.controls.identityTypes.value.value;
    if (nationalNo.length < 10 && identityType == 6) {
      this.cs.pushMessage(MessageType.Warn, null, "National No should be 10 digit");

      return false;
    }
    else if ((nationalNo.length < 6 ) && identityType == 1) {
      this.cs.pushMessage(MessageType.Warn, null, "Passport / Other document No should be between 6-10 digit");

      return false;
    }


      this.patientService.GetMemberByIdentityNo(nationalNo, identityType).subscribe(res => {
        if (res && res.data && res.data.memberId > 0) {
          this.selectedMemberId = res.data.memberId;

          this.SubmitForm.controls.firstName.setValue(res.data.firstName1);
          if (res.data.firstName1)
            this.SubmitForm.controls.firstName.disable();

          this.SubmitForm.controls.secondName.setValue(res.data.midName1);
          if (res.data.midName1)
            this.SubmitForm.controls.secondName.disable();

          this.SubmitForm.controls.thirdName.setValue(res.data.lastName1);
          if (res.data.lastName1)
            this.SubmitForm.controls.thirdName.disable();

          this.SubmitForm.controls.familyName.setValue(res.data.familyName1);
          if (res.data.familyName1)
            this.SubmitForm.controls.familyName.disable();

          /*        this.SubmitForm.controls.mobileNo.setValue(res.data.mobile);*/
          if (res.data.gender > 0) {
            let value = this.genders.find(x => x.value == res.data.gender);
            this.SubmitForm.controls.gender.setValue(value);
            this.SubmitForm.controls.gender.disable();
          }
          /* this.SubmitForm.controls.email.setValue(res.data.email);*/
          this.SubmitForm.controls.day.setValue(res.data.day);
          this.SubmitForm.controls.day.disable();
          if (res.data.month > 0) {
            let month = this.months.find(x => x.value == res.data.month);
            this.SubmitForm.controls.month.setValue(month);
            this.SubmitForm.controls.month.disable();
          }

          this.SubmitForm.controls.year.setValue(res.data.year);
          this.SubmitForm.controls.year.disable();
          //if (res.data.cityId > 0) {
          //  this.fillLab(res.data.cityId)
          //  let city = this.governorates.find(x => x.value == res.data.cityId);
          //  this.SubmitForm.controls.governorate.setValue(city);
          //}

        } else {
          this.selectedMemberId = 0;
          this.SubmitForm.controls.firstName.setValue(null);
          this.SubmitForm.controls.secondName.setValue(null);
          this.SubmitForm.controls.thirdName.setValue(null);
          this.SubmitForm.controls.familyName.setValue(null);
          this.SubmitForm.controls.gender.setValue(null);
          this.SubmitForm.controls.day.setValue(null);
          this.SubmitForm.controls.month.setValue(null);
          this.SubmitForm.controls.year.setValue(null);
        }
      });

  }
  hideInvalidAge() {
    this.showInvalid = false;
    if (this.selectedEncryptVisitRegistrationId && this.selectedEncryptVisitRegistrationId != '')
      this.router.navigate(['/registration-tracking'], { queryParams: { id: this.selectedEncryptVisitRegistrationId } });
  }
  showAgreeConditions() {
    this.showAgreeCondition = true;
  }
  hideAgreeConditions() {
    this.showAgreeCondition = false;
  }
  onChangeIdentity(event) {
    let value = event.value.value;

    this.SubmitForm.controls.nationalNo.clearValidators()
    if (value == 1) {
      this.showNationalLable = false;
      this.SubmitForm.controls.nationalNo.setValue('');
      this.SubmitForm.controls.nationalNo.addValidators([Validators.required,Validators.minLength(6), Validators.maxLength(10)]);
    } else {
      this.showNationalLable = true;
      this.SubmitForm.controls.nationalNo.setValue('');
      this.SubmitForm.controls.nationalNo.addValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)]);
    }

    this.SubmitForm.controls.nationalNo.updateValueAndValidity()

  }

  onChangeMonth(event) {
    let year = this.SubmitForm.controls.year.value;
    this.Filldays(event.value.value, year);
  }
  onChangeYear(event) {
    let month = this.SubmitForm.controls.month.value;
    this.Filldays(month,event.value.value);
  }
  buildSubmitForm() {

    this.SubmitForm = new FormGroup({
      'nationality': new FormControl({ value: 1 }, [Validators.required]),
      'identityTypes': new FormControl({ value: 6 }, [Validators.required]),
      'nationalNo': new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      /*'passportNo': new FormControl({ value: '', disabled: true },),*/
      'firstName': new FormControl(null, [Validators.required]),
      'secondName': new FormControl(null,),
      'thirdName': new FormControl(null,),
      'familyName': new FormControl(null, [Validators.required]),
      'gender': new FormControl(null, [Validators.required]),
      'day': new FormControl(null, [Validators.required]),
      'month': new FormControl(null, [Validators.required]),
      'year': new FormControl(null, [Validators.required]),
      'mobileNo': new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      'governorate': new FormControl(null, [Validators.required]),
      'lab': new FormControl(null, [Validators.required]),
      'email': new FormControl(null,),
      'attributeOne': new FormControl(null, [Validators.required]),
      'attributeTwo': new FormControl(null, [Validators.required]),
      'attributeThree': new FormControl(null, [Validators.required]),
      'attributeFour': new FormControl(null, [Validators.required]),
      'attributeFive': new FormControl(null, [Validators.required]),
      'acknowledge': new FormControl(null, [Validators.required]),
      'agreeTerms': new FormControl(null, [Validators.required]),
      
    });
  }
  buildOTPForm() {
   
    this.OTPForm = new FormGroup({
      'OTPCode': new FormControl(null, [Validators.required]),
    });

  }

 
  fillNationality() {
    this.nationalities = [
      { name: 'Jordanian', value: 1 },
      { name: 'Non-Jordanian', value: 2 },
    ];
  }
  fillIdentityType() {
    this.identityTypes = [
      { name: 'National ID Card', value: 6 },
      { name: 'Passport / Other document No.', value: 1 },
    ];
  }


  fillGender() {
    this.genders = [
 
      { name: 'Male / ذكر', value: 1 },
      { name: 'Female / أنثى', value: 2 },
    ];
  }

  fillGovernorate() {
    this.governorates = [
      { name: 'Amman / عمان', value: 12 },
      { name: 'Rusifa / الرصيفة', value: 2 },
      { name: 'Zarqa / الزرقاء', value: 4 },
      { name: 'Irbid / اٍربد', value: 6 },
      { name: 'Aqaba / العقبة', value: 5 },
      { name: 'Sahab / سحاب', value: 457 },
      { name: 'Karak / الكرك', value: 8 },
      { name: 'Maan / معان', value: 9 },
      { name: 'Tafila / الطفيلة', value: 10 },
      { name: 'Al-Balqa / العقبة', value: 11 },
      { name: 'Mafraq / المفرق', value: 26 },
      { name: 'Alfuheis / الفحيص', value: 27 },
      { name: 'Marj Al Hamam / مرج الحمام', value: 28 },
      { name: 'Mutah / مؤتة', value: 29 },
      { name: 'Ghor / الغور', value: 31 },
      { name: 'Jerash / جرش', value: 13 },
      { name: 'Petra / البتراء', value: 33 },
      { name: 'Madaba / مادبا', value: 15 },
      { name: 'Ajlun / عجلون', value: 3 },
      { name: 'Azraq / الأزرق', value: 336 },
      { name: 'Ramtha / الرمثا', value: 338 },
    ];
  }
  FillYears() {

    this.years = [];
    for (let year = 1960; year <= 2005; year++)
    {
      this.years.push(year)
    }
  }
  FillMonths() {
    this.months = [

      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 },
      { value: 11 },
      { value: 12 },
    ];
  }

  Filldays(month: number,year:number) {
    const dayCount = this.getDaysInMonth(year, month)
    this.days= Array(dayCount).fill(0).map((i, idx) => idx + 1)
  }

  public getDaysInMonth(year: number, month: number) {
    return 32 - new Date(year, month - 1, 32).getDate();
  }

 async FillAttributesOne() {
    this.attributesOne = [
      { name: 'Yes / نعم', value: this.environment.attributesOneYesId },
      { name: 'No / لا', value: this.environment.attributesOneNoId },
    ];
   await this.patientService.GetAttributeInfo(this.environment.attributesOneId).subscribe(res => {
      if (res && res.data) {
        this.AttributesOnelabel1 = res.data.label1;
        this.AttributesOnelabel2 = res.data.label2;
      }
    });
  }
  async FillAttributesTwo() {
    this.attributesTwo = [
      { name: 'Yes / نعم', value: this.environment.attributesTwoYesId },
      { name: 'No / لا', value: this.environment.attributesTwoNoId },
    ];

    await this.patientService.GetAttributeInfo(this.environment.attributesTwoId).subscribe(res => {
      if (res && res.data) {
        this.AttributesTwolabel1 = res.data.label1;
        this.AttributesTwolabel2 = res.data.label2;
      }
    });

  }
  async FillAttributesThree() {
    this.attributesThree = [
      { name: 'Yes / نعم', value: this.environment.attributesThreeYesId },
      { name: 'No / لا', value: this.environment.attributesThreeNoId },
    ];

    await this.patientService.GetAttributeInfo(this.environment.attributesThreeId).subscribe(res => {
      if (res && res.data) {
        this.AttributesThreelabel1 = res.data.label1;
        this.AttributesThreelabel2 = res.data.label2;
      }
    });


  }
  async FillAttributesFour() {
    this.attributesFour = [
      { name: 'Yes / نعم', value: this.environment.attributesFourYesId },
      { name: 'No / لا', value: this.environment.attributesFourNoId },
    ];

    await this.patientService.GetAttributeInfo(this.environment.attributesFourId).subscribe(res => {
      if (res && res.data) {
        this.AttributesFourlabel1 = res.data.label1;
        this.AttributesFourlabel2 = res.data.label2;
      }
    });


  }
  async FillAttributesFive() {
    this.attributesFive = [
      { name: 'Yes / نعم', value: this.environment.attributesFiveYesId },
      { name: 'No / لا', value: this.environment.attributesFiveNoId },
    ];

    await this.patientService.GetAttributeInfo(this.environment.attributesFiveId).subscribe(res => {
      if (res && res.data) {
        this.AttributesFivelabel1 = res.data.label1;
        this.AttributesFivelabel2 = res.data.label2;
      }
    });

  }

  fillLab(cityId) {
    this.patientService.GetActiveProviderList(cityId).subscribe(res => {
      if (res && res.data) {
        
        this.labs = res.data;
      }
    });
  }


  checkValidatorForm() {
    if (this.SubmitForm.controls["nationality"].value != null && this.SubmitForm.controls["nationality"].value.value > 0 &&
      this.SubmitForm.controls["identityTypes"].value != null && this.SubmitForm.controls["identityTypes"].value.value > 0 &&
      this.SubmitForm.controls["nationalNo"].value != null &&
      this.SubmitForm.controls["firstName"].value != null &&
      this.SubmitForm.controls["familyName"].value != null &&
      this.SubmitForm.controls["gender"].value != null && this.SubmitForm.controls["gender"].value.value > 0 &&
      this.SubmitForm.controls["day"].value != null && this.SubmitForm.controls["day"].value > 0 &&
      this.SubmitForm.controls["month"].value != null && this.SubmitForm.controls["month"].value.value > 0 &&
      this.SubmitForm.controls["year"].value != null && this.SubmitForm.controls["year"].value > 0 &&
      this.SubmitForm.controls["mobileNo"].value != null &&
      this.SubmitForm.controls["governorate"].value != null && this.SubmitForm.controls["governorate"].value.value > 0 &&
      this.SubmitForm.controls["lab"].value != null && this.SubmitForm.controls["lab"].value.id > 0 &&
      this.SubmitForm.controls["attributeOne"].value != null && this.SubmitForm.controls["attributeOne"].value.value > 0 &&
      this.SubmitForm.controls["attributeTwo"].value != null && this.SubmitForm.controls["attributeTwo"].value.value > 0 &&
      this.SubmitForm.controls["attributeThree"].value != null && this.SubmitForm.controls["attributeThree"].value.value > 0 &&
      this.SubmitForm.controls["attributeFour"].value != null && this.SubmitForm.controls["attributeFour"].value.value > 0 &&
      this.SubmitForm.controls["attributeFive"].value != null && this.SubmitForm.controls["attributeFive"].value.value > 0 &&
      this.SubmitForm.controls["acknowledge"].value != null && this.SubmitForm.controls["acknowledge"].value &&
      this.SubmitForm.controls["agreeTerms"].value != null && this.SubmitForm.controls["agreeTerms"].value
    ) {
      return true;
    }
    else {

      this.cs.pushMessage(MessageType.Warn, null,"Please check input");

      return false;

    }
  }


  phoneValidatorNumber(inputtxt) {

    var phoneno = /^\(?([0,7]{2})\)?[-. ]?([7,8,9]{1})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (inputtxt.match(phoneno)) {
    return true;
  }
    else {
      this.SubmitForm.controls["mobileNo"].setValue(null);
    this.cs.pushMessage(MessageType.Warn, null, "mobile number should start with 078 or 077 or 079");

    return false;
  }
  }


}
