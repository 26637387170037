import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, map, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PatientService {
  private httpHeaders = new HttpHeaders()
    //.set('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept, Authorization')
    .set('Authorization', 'bearer ' + localStorage.getItem("MedlUToken"));

  private environment;
  private url;
  attributesOneId: number;
  attributesTwoId: number;
  attributesThreeId: number;
  attributesFourId: number;
  attributesFiveId: number;
  constructor(private httpClient: HttpClient) {

    this.environment = environment;
    this.url = this.environment.url;
    this.attributesOneId = this.environment.attributesOneId;
    this.attributesTwoId = this.environment.attributesTwoId;
    this.attributesThreeId = this.environment.attributesThreeId;
    this.attributesFourId = this.environment.attributesFourId;
    this.attributesFiveId = this.environment.attributesFiveId;
  }



  GetMemberByIdentityNo(identityNo: string, identityType: number) {
    return this.httpClient.get<any>(`${this.url}/api/Campaign/GetMemberByIdentityNo?identityNo=` + identityNo + `&identityType=` + identityType, { headers: this.httpHeaders });
  }


  GetActiveProviderList(cityId: number) {
    return this.httpClient.get<any>(`${this.url}/api/Campaign/GetActiveProviderList?cityId=` + cityId, { headers: this.httpHeaders });
  }

  SendOTP(mobileNo: string, msgLangauge: string) {
    return this.httpClient.get<any>(`${this.url}/api/Campaign/SendOTP?mobileNo=` + mobileNo + `&msgLangauge=` + msgLangauge, { headers: this.httpHeaders });
  }

  CheckOTP(otpId: number, otpCode: string) {
    return this.httpClient.get<any>(`${this.url}/api/Campaign/CheckOTP?otpId=` + otpId + `&otpCode=` + otpCode, { headers: this.httpHeaders });
  }

  GetAttributeInfo(attributeId: number) {
    return this.httpClient.get<any>(`${this.url}/api/Campaign/GetAttributeInfo?attributeId=` + attributeId, { headers: this.httpHeaders });
  }

  IsValidRegistarion(providerCode: string) {
    return this.httpClient.get<any>(`${this.url}/api/Campaign/IsValidRegistarion?providerCode=` + providerCode, { headers: this.httpHeaders });
  }

  SendSMS(visitRegistrationId: number, encryptVisitRegistrationId: string) {
    return this.httpClient.get<any>(`${this.url}/api/Campaign/SendSMS?visitRegistrationId=` + visitRegistrationId + `&encryptVisitRegistrationId=` + encryptVisitRegistrationId, { headers: this.httpHeaders });
  }

  SaveVisitRegistration(nationalityId, identityType, identityNo, firstName, secondName, thirdName, familyName, gender, birthDaye, birthMonth, birthYear, mobileNo, cityId, providerId, providerCode,
    email, attributesOneAnswerId, attributesTwoAnswerId, attributesThreeAnswerId, attributesFourAnswerId, attributesFiveAnswerId, labOrderStatus,memberId): Observable<any> {
    let data = {
      'nationalityId': nationalityId,
      'identityType': identityType,
      'identityNo': identityNo,
      'firstName': firstName,
      'secondName': secondName,
      'thirdName': thirdName,
      'familyName': familyName,
      'gender': gender,
      'birthDaye': birthDaye,
      'birthMonth': birthMonth,
      'birthYear': birthYear,
      'mobileNo': mobileNo,
      'cityId': cityId,
      'providerId': providerId,
      'providerCode': providerCode,
      'email': email,
      'attributesOneId': this.attributesOneId,
      'attributesOneAnswerId': attributesOneAnswerId,
      'attributesTwoId': this.attributesTwoId,
      'attributesTwoAnswerId': attributesTwoAnswerId,
      'attributesThreeId': this.attributesThreeId,
      'attributesThreeAnswerId': attributesThreeAnswerId,
      'attributesFourId': this.attributesFourId,
      'attributesFourAnswerId': attributesFourAnswerId,
      'attributesFiveId': this.attributesFiveId,
      'attributesFiveAnswerId': attributesFiveAnswerId,
      'labOrderStatus': labOrderStatus,
      'memberId': memberId
    };
    const url = `${this.url}/api/Campaign/SaveVisitRegistration`;
    return this.httpClient.post(url, data, { headers: this.httpHeaders });
  }

  GetVisitRegistrationById(visitRegistrationId: number) {
    return this.httpClient.get<any>(`${this.url}/api/Campaign/GetVisitRegistrationById?visitRegistrationId=` + visitRegistrationId, { headers: this.httpHeaders });
  }
}
