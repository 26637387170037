import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';

import { InputNumberModule } from 'primeng/inputnumber';
import { AutoCompleteModule } from 'primeng/autocomplete';
 
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import {DialogModule} from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import {FieldsetModule} from 'primeng/fieldset';
import {ChartModule} from 'primeng/chart';
import {AccordionModule} from 'primeng/accordion';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HomeComponent } from './home/home.component';
import { CalendarModule } from 'primeng/calendar';
import { AppInterceptorService } from './services/shared/app-interceptor.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { OrderByPipe } from './shared/order-by.pipe';
import { PanelModule } from 'primeng/panel';
import { ProviderSettingsComponent } from './provider-settings/provider-settings.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { LabVisitRegestrationComponent } from './lab-visit-regestration/lab-visit-regestration.component';
import { LoginComponent } from './login/login.component';
import { RegistrationTrackingComponent } from './registration-tracking/registration-tracking.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    OrderByPipe,
    ProviderSettingsComponent,
    LabVisitRegestrationComponent,
    LoginComponent,
    RegistrationTrackingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InputTextareaModule,
    TableModule,
    InputNumberModule,
    AutoCompleteModule,
    InputTextModule,
    RippleModule,
    ButtonModule,
    DialogModule,
    DropdownModule,
    SidebarModule,
    FieldsetModule,
    ChartModule,
    AccordionModule,
    SlickCarouselModule,
    AppRoutingModule,
    CalendarModule,
    NgxSpinnerModule,
    ToastModule,
    PanelModule,
    DialogModule,
    InputSwitchModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AppInterceptorService,
    multi: true
  },
    MessageService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
