import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root' 
})
export class ProviderSettingService {

  private httpHeaders = new HttpHeaders() 
  //.set('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept, Authorization')
    .set('Authorization','bearer '+localStorage.getItem("MedlUToken"))

  private environment;
  private url;

  constructor(private httpClient: HttpClient) {

    this.environment = environment;
    this.url = this.environment.url;
  }

  GetAllProviderSettings(): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/api/ProviderSettings/GetAllProviderSettings`, { headers: this.httpHeaders })
  }
  
  UpdateProviderSettings(providerSetting): Observable<any> {

    const url = this.url + '/api/ProviderSettings/UpdateProviderSetting';
    return this.httpClient.post(url, providerSetting);
  }

  CheckProviderIsExist(username, password): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/api/ProviderSettings/CheckProviderIsExist?username=${username}&password=${password}`, { headers: this.httpHeaders })
  }

  CheckQrCode(visitRegistrationId): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/api/ProviderSettings/CheckQrCode?visitRegistrationId=${visitRegistrationId}`, { headers: this.httpHeaders })
  }

  UpdateVisitRegistration(visitRegistrationId): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/api/ProviderSettings/UpdateVisitRegistration?visitRegistrationId=${visitRegistrationId}`, { headers: this.httpHeaders })
  }
}
