  <!-- begin:: Content -->

<div class="kt-portlet--no_navigation">
  <section class="features-icons bg-light text-center">
    <div class="kt-portlet profile-area p-4">
      <div class="row">
        <div class="col-md-3 mt-3">
          <h1> MedLabs 30 Years Health Campaign</h1>
        </div>
        <div class="col-6">
          <a>
            <img alt="Logo"
                 src="./assets/media/logos/rght-side-menu.png">
          </a>
        </div>
        <div class="col-md-3 mt-3">
          <h1>حملة مدلاب 30 عاما لصحة الأردن</h1>
        </div>

      </div>
    </div>
  </section>


  

      <div class="kt-portlet kt-portlet--head-sm search-area" data-ktportlet="true" id="kt_portlet_tools_2">


        <!--begin::Form-->
        <form class="kt-form" kt-hidden-height="195">
          <div class="kt-portlet__body">


            <form [formGroup]="SubmitForm">
              <div class="row form-home-page">
                <div class="form-group col-md-3">
                  <label for="status"><span>Nationality / </span> <span>الجنسية </span></label>
                  <p-dropdown [options]="nationalities" formControlName="nationality" optionLabel="name" inputId="nationality" class="required" styleClass='form-control'></p-dropdown>

                </div>
                <div class="form-group col-md-3">
                  <label for="status"><span>Identity Type / </span> <span>نوع الوثيقة </span></label>
                  <p-dropdown [options]="identityTypes" optionLabel="name" inputId="identityType" class="required" styleClass='form-control'
                              formControlName="identityTypes" (onChange)="onChangeIdentity($event)"></p-dropdown>
                </div>
                <div class="form-group col-md-3">
                  <label for="range" *ngIf="showNationalLable"><span>National No. / </span> <span>رقم الوطني </span></label><label for="rangee" *ngIf="!showNationalLable"><span>Passport / Other document No. / </span> <span>جواز السفر / وثيقة شخصية </span></label>
                  <input type="text" class="form-control required" formControlName="nationalNo" maxlength="10" (change)="onNationalChange($event.target.value)">
                </div>
                <div class="form-group col-md-3">

                  <label for="range"><span>Mobile No. / </span> <span>الموبايل </span></label>
                  <input type="text" class="form-control required" placeholder="07xxxxxxxx" formControlName="mobileNo" maxlength="10" (change)="phoneValidatorNumber($event.target.value)">

                  <!--<div class="form-group form-group-sm col-md-2">
                    <label></label>
                    <img src="../../assets/media/icons/one-time-password.png" width="35" height="35" (click)="SendOTP()" style="cursor:pointer;" />
                  </div>-->
                </div>
                <!--<div class="col-md-3">
                  <label for="range"><span>Passport / Other document No. / </span> <span>جواز السفر / وثيقة شخصية </span></label>
                  <input type="text" class="form-control required" formControlName="passportNo">
                </div>-->

                <div class="form-group col-md-3">
                  <label for="range"><span>First Name / </span> <span>الاسم الأول </span></label>
                  <input type="text" class="form-control required" formControlName="firstName">
                </div>
                <div class="form-group col-md-3">
                  <label for="range"><span>Second Name / </span> <span>الاسم الثاني </span></label>
                  <input type="text" class="form-control" formControlName="secondName">
                </div>
                <div class="form-group col-md-3">
                  <label for="range"><span>Third Name / </span> <span>الاسم الثالث </span></label>
                  <input type="text" class="form-control" formControlName="thirdName">
                </div>
                <div class="form-group  col-md-3">
                  <label for="range"><span>Family Name / </span> <span>إسم العائلة </span></label>
                  <input type="text" class="form-control required" formControlName="familyName">
                </div>
                <div class="form-group  col-md-3">
                  <label for="status"><span>Gender / </span> <span>الجنس </span></label>
                  <p-dropdown [options]="genders" optionLabel="name" [showClear]="true" inputId="gender" class="required" styleClass='form-control'
                              formControlName="gender" placeholder="Please Select (يرجى اختيار)"></p-dropdown>
                </div>
                <div class="input-group form-group col-md-3">
                  <label for="Address"><span>Birthdate /</span> <span>تاريخ الولادة</span></label>
                  <div class="form-group  col-md-4">
                    <p-dropdown [options]="days" [showClear]="true" inputId="day" class="required" styleClass='form-control'
                                formControlName="day" placeholder="DD" [(ngModel)]="selectedday">

                      <ng-template let-day pTemplate="item">
                        {{day}}
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="form-group  col-md-4">
                    <p-dropdown [options]="months" optionLabel="value" [showClear]="true" inputId="day" class="required" styleClass='form-control'
                                formControlName="month" placeholder="MM" [(ngModel)]="selectedMonth" (onChange)="onChangeMonth($event)">
                      <ng-template let-month pTemplate="item">
                        {{month.value}}
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="form-group  col-md-4">
                    <p-dropdown [options]="years" [showClear]="true" inputId="year" class="required" styleClass='form-control'
                                formControlName="year" placeholder="YYYY" (onChange)="onChangeYear($event)">
                      <ng-template let-year pTemplate="item">
                        {{year}}
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>




                <div class=" form-group col-md-3">
                  <label for="status"><span>Governorate / </span> <span>المحافظة </span></label>
                  <p-dropdown [options]="governorates" optionLabel="name" [showClear]="true" inputId="governorate" class="required" styleClass='form-control'
                              formControlName="governorate" placeholder="Please Select (يرجى اختيار)" (onChange)="onChangeGovernorate($event)"></p-dropdown>
                </div>
                <div class="form-group col-md-3">
                  <label for="status"><span>Lab / </span> <span>مختبر </span></label>
                  <p-dropdown [options]="labs" optionLabel="providerName" [showClear]="true" inputId="lab" class="required" styleClass='form-control'
                              formControlName="lab" placeholder="Please Select (يرجى اختيار)" (onChange)="onChangelabs()"></p-dropdown>
                </div>

                <div class="form-group col-md-6 label-h">
                  <label for="range"><span>Email / </span> <span>البريد الإلكتروني </span></label>
                  <input type="text" class="form-control" formControlName="email">
                </div>

                <div class="form-group col-md-6 label-h ">
                  <label for="status"><span>{{AttributesOnelabel1}} / </span> <span>{{AttributesOnelabel2}} </span></label>
                  <p-dropdown [options]="attributesOne" formControlName="attributeOne" placeholder="Please Select (يرجى اختيار)" optionLabel="name" inputId="attributeOne" class="required" styleClass='form-control'></p-dropdown>

                </div>
                <div class="form-group col-md-6 label-h ">
                  <label for="status"><span>{{AttributesTwolabel1}} / </span> <span>{{AttributesTwolabel2}} </span></label>
                  <p-dropdown [options]="attributesTwo" formControlName="attributeTwo" placeholder="Please Select (يرجى اختيار)" optionLabel="name" inputId="attributeTwo" class="required" styleClass='form-control'></p-dropdown>

                </div>
                <div class="form-group col-md-6 label-h">
                  <label for="status"><span>{{AttributesThreelabel1}} / </span> <span>{{AttributesThreelabel2}} </span></label>
                  <p-dropdown [options]="attributesThree" formControlName="attributeThree" placeholder="Please Select (يرجى اختيار)" optionLabel="name" inputId="attributeThree" class="required" styleClass='form-control'></p-dropdown>

                </div>
                <div class="form-group col-md-6 label-h ">
                  <label for="status"><span>{{AttributesFourlabel1}} / </span> <span>{{AttributesFourlabel2}} </span></label>
                  <p-dropdown [options]="attributesFour" formControlName="attributeFour" placeholder="Please Select (يرجى اختيار)" optionLabel="name" inputId="attributeFour" class="required" styleClass='form-control'></p-dropdown>

                </div>
                <div class="form-group col-md-6 label-h ">
                  <label for="status"><span>{{AttributesFivelabel1}} / </span> <span>{{AttributesFivelabel2}}  </span></label>
                  <p-dropdown [options]="attributesFive" formControlName="attributeFive" placeholder="Please Select (يرجى اختيار)" optionLabel="name" inputId="attributeFive" class="required" styleClass='form-control'></p-dropdown>

                </div>

                <div class="col-md-6">
                  <div class="kt-checkbox-list">
                    <label class="kt-checkbox">
                      <input type="checkbox" formControlName="acknowledge">I acknowledge that all the information I have entered is correct. /
                      أنا اؤكد أن جميع المعلومات المدخلة صحيحة
                      <span></span>
                    </label>
                    <label class="kt-checkbox">
                      <input type="checkbox" formControlName="agreeTerms">
                      <a (click)="showAgreeConditions()" class="stretched-link">I agree to the terms and conditions /</a>
                      <a (click)="showAgreeConditions()" class="stretched-link">أوافق على جميع الشروط والاحكام</a>
                      <span></span>  
                    </label>
                  </div>
                </div>
              </div>

            </form>


          </div>
          <div class="kt-portlet__foot kt-portlet__foot--sm">
            <div class="kt-form__actions kt-form__actions--center">
              <button class="btn btn-primary" type="button" [disabled]="!SubmitForm.valid" (click)="SubmitRegistration()">SUBMIT | ارسال</button>
            </div>

          </div>
        </form>
        <!--end::Form-->
      </div>
   


</div>


<p-dialog [(visible)]="showAgreeCondition" [style]="{width: '30vw'}" [modal]="true">
  <ng-template pTemplate="header">
    Terms and Conditions
  </ng-template>

  <ng-template pTemplate="body">
    <div class="form-group form-group-sm col-md-12">
      I agree that my clinical information (age,gender and medical,information) will be used for the interest of research only and with ultimate confidentiality. My name, phone number and national ID will NEVER be used.
    </div>
    <div class="form-group form-group-sm col-md-12">
      أوافق أن يتم استخدام عينتي ومعلوماتي (العمر,الجنس,تاريخ ومعلومات طبية) لأغراض البحث العلمي فقط بسرية تامه.ولن يتم استخدام اسمي أو رقم هاتفي أو الرقم الوطني
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="kt-portlet__foot kt-portlet__foot--sm">
      <div class="kt-form__actions kt-form__actions--center" style="text-align:center !important">
        <button class="btn btn-primary" type="button" (click)="hideAgreeConditions()">OK</button>

      </div>
    </div>

  </ng-template>
</p-dialog>

<p-dialog [(visible)]="showInvalid" [style]="{width: '30vw'}" [modal]="true">
  <ng-template pTemplate="header">
    Alert
  </ng-template>

  <ng-template pTemplate="body">
    <div class="form-group form-group-sm col-md-12">
      {{lblMsgOne}}
    </div>
    <div class="form-group form-group-sm col-md-12">
      {{lblMsgTwo}}
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="kt-portlet__foot kt-portlet__foot--sm">
      <div class="kt-form__actions kt-form__actions--center" style="text-align:center !important">
        <button class="btn btn-primary" type="button" (click)="hideInvalidAge()">OK</button>

      </div>
    </div>

  </ng-template>
</p-dialog>

<p-dialog [(visible)]="showOTP" [style]="{width: '30vw'}" [modal]="true">


  <ng-template pTemplate="header">
    OTP Verification
  </ng-template>

  <ng-template pTemplate="body">
    <form [formGroup]="OTPForm">
      <div class="form-group form-group-sm col-md-12">
        <label>Verification Code</label>
        <input type="text" class="form-control required" formControlName="OTPCode" maxlength="5" />
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="kt-portlet__foot kt-portlet__foot--sm" >
      <div class="kt-form__actions kt-form__actions--center" style="text-align:center !important">
        <button class="btn btn-primary" type="button" (click)="OTPVerification()" [disabled]="!OTPForm.valid">OK</button>

      </div>
    </div>

  </ng-template>
  <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="showErrorMsg">
    <div class="alert-text">{{errorMsg}}</div>
  </div>
</p-dialog>

<!-- begin:: Footer -->

<div class="kt-footer  kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
  <div class="kt-container  kt-container--fluid ">
    <div _ngcontent-gul-c63="" class="kt-footer__copyright">
      2023 © <a _ngcontent-gul-c63="" href="https://www.eskadenia.com/" target="_blank" title="ESKADENIA Software" class="kt-link kt-font-brand">
        ESKADENIA Software
      </a>.&nbsp;All rights reserved.
    </div>
    <div class="kt-footer__menu">
      <a href="https://eskadenia.com" target="_blank" class="img" title="ESKADENIA Software"><img src="assets/media/logos/logo-dark.png" alt="ESKADENIA Software"></a>
    </div>
  </div>
</div>


